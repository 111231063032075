export default {
  seo: {
    title: 'Финансовая отчетность за 2024 год | Банк 131',
    description: 'Финансовая отчетность за 2024 год',
  },
  title: 'Финансовая отчетность за 2024 год',
  content: [
    {
      label: 'Дата размещения 19.03.2025',
      title: 'Годовая бухгалтерская (финансовая) отчетность за 2024 год',
      link: '/legal/ru/godovaya-bukhgalterskaya-finansovaya-otchetnost-za-2024-god.pdf',
    },
    {
      label: 'Дата размещения 19.03.2025',
      title: 'Примечание к обобщенной промежуточной бухгалтерской финансовой отчетности за 2024 год',
      link: '/legal/ru/primechanie-k-obobshchennoi-godovoi-bukhgalterskoi-finansovoi-otchetnosti-za-2024-god.pdf',
    },

    {
      label: 'Дата размещения 19.03.2025',
      title: 'Аудиторское заключение в отношении обобщенной промежуточной бухгалтерской (финансовой) отчетности за 2024 год',
      link: '/legal/ru/auditorskoe-zaklyuchenie-nezavisimogo-auditora-v-otnoshenii-obobshchennoi-godovoi-bukhgalterskoi-finansovoi-otchetnosti.pdf',
    },
    {
      label: 'Дата размещения 21.11.2024',
      title: 'Промежуточная бухгалтерская (финансовая) отчетность за 9 месяцев 2024 года',
      link: '/legal/ru/promezhutochnaya-buhgalterskaya-finansovaya-otchetnost-za-9mesyacev-2024-goda.pdf',
    },
    {
      label: 'Дата размещения 21.11.2024',
      title: 'Примечание к обобщенной промежуточной бухгалтерской финансовой отчетности за 9 месяцев 2024 года',
      link: '/legal/ru/primechanie-k-obobshhennoj-promezhutochnoj-buxgalterskoj-finansovoj-otchetnosti-za-9-mesyacev-2024-goda.pdf',
    },
    {
      label: 'Дата размещения 21.11.2024',
      title: 'Аудиторское заключение в отношении обобщенной промежуточной бухгалтерской финансовой отчетности за 9 месяцев 2024 года',
      link: '/legal/ru/auditorskoe-zaklyuchenie-v-otnoshenii-obobshhennoj-promezhutochnoj-buxgalterskoj-finansovoj-otchetnosti-za-9-mesyacev-2024-goda.pdf',
    },
    {
      label: 'Дата размещения 02.08.2024',
      title: 'Промежуточная бухгалтерская (финансовая) отчетность за первое полугодие 2024 года',
      link: '/legal/ru/promezhutochnaya-buhgalterskaya-finansovaya-otchetnost-za-1-polugodie-2024-goda.pdf',
    },
    {
      label: 'Дата размещения 02.08.2024',
      title: 'Дополнительная информация к промежуточной бухгалтерской (финансовой) отчетности за первое полугодие 2024 года',
      link: '/legal/ru/dopolnitelnaya-informaciya-k-obobshchennoj-promezhutochnoj-buhgalterskoj-finansovoj-otchetnosti-za-1-polugodie-2024-goda.pdf',
      clarification: {
        text: 'Промежуточная бухгалтерская (финансовая) отчетность за первое полугодие 2024 года и дополнительная информация к нему, сформированная с учетом принятых Советом директоров Банка России 26.12.2023 решением «О требованиях к раскрытию кредитными организациями (головными кредитными организациями банковских групп) отчетности и информации в 2024 году».',
      }
    },
    {
      label: 'Дата размещения 14.05.2024',
      title: 'Промежуточная бухгалтерская финансовая отчетность за 1 квартал 2024 года',
      link: '/legal/ru/promezhutochnaya-buhgalterskaya-finansovaya-otchetnost-za-1-kvartal-2024-goda.pdf',
    },
    {
      label: 'Дата размещения 14.05.2024',
      title: 'Дополнительная информация к обобщенной промежуточной бухгалтерской финансовой отчетности за 1 квартал 2024 года',
      link: '/legal/ru/dopolnitelnaya-informaciya-k-obobshchennoj-promezhutochnoj-buhgalterskoj-finansovoj-otchetnosti-za-1-kvartal-2024-goda_1.pdf',
      clarification: {
        label: '',
        text: 'Промежуточная бухгалтерская (финансовая) отчетность за первый квартал 2024 года и дополнительная информация к нему, сформированная с учетом принятых Советом директоров Банка России от 26.12.2023 решением «О требованиях к раскрытию кредитными организациями (головными кредитными организациями банковских групп) отчетности и информации в 2024 году».'
      }
    },
    {
      title: '',
      link: '',
      clarification: {
        label: 'Дата размещения 02.05.2024',
        text:
          'Дата проведения очередного (годового) общего собрания участников (дата принятия решения единственного участника Акционерного общества «Банк 131») — 26.04.2024',
      },
    },
  ],
};
