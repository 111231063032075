import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from '../../components/RectangleLink';
import Clarification from '../../components/Clarification';

import enMessages from '../../i18n/legal/2024/en.js';
import ruMessages from '../../i18n/legal/2024/ru.js';

import enMainMessages from '../../i18n/LegalInformation/en.js';
import ruMainMessages from '../../i18n/LegalInformation/ru.js';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const messagesMain = {
  en: enMainMessages,
  ru: ruMainMessages,
};

const section = '2024';

const isBrowser = typeof window !== 'undefined';

const FinReports2024Page = ({ pathContext: { locale } }) => {
  if (isBrowser && locale === 'en') {
    window.location.replace('/en/legal/requisite');
  }

  return (
    <TemplateLegalPage
      locale={locale}
      messages={messages[locale]}
      messagesMain={messagesMain[locale]}
      section={section}
    >
      {messages[locale].content.map((item) => {
        return (
          <React.Fragment key={item.link}>
            {item.title ? (
              <RectangleLink
                title={item.title}
                label={item.label}
                link={item.link}
                target="_blank"
              />
            ) : null}

            {item.clarification ? (
              <Clarification
                label={item.clarification.label}
                text={item.clarification.text}
              />
            ) : null}
          </React.Fragment>
        );
      })}
    </TemplateLegalPage>
  );
};

export default FinReports2024Page;
